import { DefaultTheme } from "styled-components";

export const COLORS = {
  // Teal
  primary: {
    normal: "var(--color-teal)",
    light: "#FAFEFD", // Seems to be unused
    transparent: "var(--color-teal-opacity-25)",
  },

  primaryReversed: {
    dark: "var(--color-teal)",
    normal: "var(--color-white)",
  },

  // Crimson
  secondary: {
    normal: "var(--color-pink)",
    light: "var(--color-pink)",
    transparent: "var(--color-orange-opacity-025)",
  },

  // Blue
  tertiary: {
    normal: "var(--color-blue)",
    light: "var(--color-theme-blue-light)",
    transparent: "var(--color-blue-opacity-5)",
  },

  // Black
  text: {
    normal: "var(--color-black)",
    light: "var(--color-black)",
    transparent: "var(--color-black)",
  },

  // White
  white: {
    normal: "var(--color-white)",
    light: "var(--color-white)",
    transparent: "var(--color-white-opacity-70)",
  },

  // Black
  black: {
    normal: "var(--color-black)",
    light: "var(--color-black)",
    transparent: "var(--color-black)",
  },

  // Grey
  grey: {
    normal: "var(--color-theme-grey)",
    light: "var(--color-theme-grey)",
    transparent: "var(--color-theme-grey)",
  },

  // Error red
  error: {
    normal: "var(--color-red)",
    light: "var(--color-red)",
    transparent: "var(--color-red)",
  },

  // Success
  success: {
    normal: "var(--color-theme-success)",
    light: "var(--color-theme-success)",
    transparent: "var(--color-theme-success)",
  },

  // Orange
  info: {
    normal: "var(--color-orange)",
    light: "var(--color-theme-orange-light)",
    transparent: "var(--color-orange-opacity-70)",
  },
  // Orange
  infoReversed: {
    normal: "var(--color-orange)",
    light: "var(--color-theme-orange-light)",
    transparent: "var(--color-orange-opacity-70)",
  },
  // Green
  headerMain: {
    normal: "var(--color-teal)",
    light: "var(--color-white)",
    transparent: "var(--color-orange-opacity-70)",
  },
};

export const SHADOWS = {
  sm: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  cardDefault: "0px 4px 25px 0px rgba(0, 0, 0, 0.1)",
  cardHover: "0px 4px 25px 0px rgba(0, 0, 0, 0.2)",
};

export const FONTS = {
  primary: "Fjalla One",
  secondary: "Avenir",
};

export const FONT_SIZES = {
  h1: {
    mobile: "32px",
    desktop: "48px",
  },
  h2: {
    mobile: "24px",
    desktop: "40px",
  },
  h3: {
    mobile: "16px",
    desktop: "32px",
  },
  h4: {
    mobile: "16px",
    desktop: "24px",
  },
  h5: {
    mobile: "16px",
    desktop: "24px",
  },
  h6: {
    mobile: "16px",
    desktop: "18px",
  },
  p: {
    mobile: "16px",
    desktop: "18px",
  },
};

export const BREAKPOINTS = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 410,
  tablet: 768,
  laptop: 1024,
  carouselLaptop: 1130,
  laptopL: 1440,
  desktop: 1800,
  desktopL: 2560,
};

export const DEVICES = {
  mobileS: `(min-width: ${BREAKPOINTS.mobileS}px)`,
  mobileM: `(min-width: ${BREAKPOINTS.mobileM}px)`,
  mobileL: `(min-width: ${BREAKPOINTS.mobileL}px)`,
  tablet: `(min-width: ${BREAKPOINTS.tablet}px)`,
  laptop: `(min-width: ${BREAKPOINTS.laptop}px)`,
  carouselLaptop: `(min-width: ${BREAKPOINTS.carouselLaptop}px)`,
  laptopL: `(min-width: ${BREAKPOINTS.laptopL}px)`,
  desktop: `(min-width: ${BREAKPOINTS.desktop}px)`,
  desktopL: `(min-width: ${BREAKPOINTS.desktop}px)`,
};

export const DEFAULT_THEME: DefaultTheme = {
  name: "default",
  colors: COLORS,
  shadows: SHADOWS,
  fonts: FONTS,
  fontSizes: FONT_SIZES,
  breakpoints: BREAKPOINTS,
  devices: DEVICES,
};
